import React from 'react';
import { useRouter } from 'next/router';
import { FaHome } from 'react-icons/fa';
import { Button } from '@mui/material';
import { useTranslation } from '../config/i18n';
import styles from './Custom404.module.css';

export default function Custom404() {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h1 className={styles.errorCode}>404</h1>
        <p className={styles.errorMessage}>
          {t('Oops! We could not find the page you were looking for.')}
        </p>
        <p className={styles.errorDescription}>
          {t('Our researchers are still trying to find this page. It&apos;s like a lost conference abstract.')}
        </p>
        <Button
          onClick={() => router.push('/')}
          className={styles.homeButton}
          aria-label={t('Go to Homepage')}
          type="button"
        >
          <FaHome className={styles.homeIcon} size={24} />
          {t('Go to Homepage')}
        </Button>
      </div>
    </div>
  );
}
